// sessionUtils.js

export const storeSession = (token) => {
    const sessionData = {
      token,
      timestamp: Date.now(), // Store current time in milliseconds
    };
    localStorage.setItem('userSession', JSON.stringify(sessionData));
  };
  
  export const isSessionValid = () => {
    const session = JSON.parse(localStorage.getItem('userSession'));
    if (!session) return false;
  
    const currentTime = Date.now();
    const sessionTime = session.timestamp;
    const sessionDuration = 1 * 60 * 1000; // 1 minute in milliseconds
  
    return currentTime - sessionTime < sessionDuration;
  };
  
  export const clearSession = () => {
    localStorage.removeItem('userSession');
  };
  