import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../components/components.css";
import SideBar from "./SideBar";
import logo from "../logo2.png";
import SearchBar from "./NavBar/SearchBar";
function NavigationBar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeSidebar = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="container">
        <button className="toggle-button" onClick={toggleMenu}>
          ☰
        </button>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <nav>
          <Link to="/Terms">Terms & Condition</Link>
          <Link to="/FAQ">FAQ</Link>
          <Link to="/About">About</Link>
        </nav>
        <SearchBar />
        <div className="auth">
          <Link to="/login">Log in</Link>
          <Link to="/RegisterPage" className="get-started">Sign up</Link>
        </div>
      </div>
      <SideBar isActive={menuOpen} closeSidebar={closeSidebar} />
    </header>
  );
}

export default NavigationBar;

