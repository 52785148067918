import React, { useState } from 'react';
import './HeaderReusable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleNavigation = (section) => {
        setIsNavOpen(false);
        switch (section) {
            case 'about':
                navigate('/about');
                break;
            case 'faqs':
                navigate('/faqs');
                break;
            case 'reviews':
                navigate('/reviews');
                break;
            default:
                break;
        }
    };

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <header className="header-reusable">
            <img src="./logo2.png" alt="Logo" className="logo" />
            <nav className={`nav-client ${isNavOpen ? 'active' : ''}`}>
                <button 
                    className="nav-link" 
                    onClick={() => handleNavigation('about')}
                >
                    About
                </button>
                <button 
                    className="nav-link" 
                    onClick={() => handleNavigation('faqs')}
                >
                    Faqs
                </button>
                <button 
                    className="nav-link" 
                    onClick={() => handleNavigation('reviews')}
                >
                    Reviews
                </button>
            </nav>
            <div className="search-container">
                <input type="text" className="search-client" placeholder="Search..." />
                <FontAwesomeIcon icon={faSearch} className="search-icon-client" />
            </div>
            <div className="hamburger" onClick={toggleNav}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </header>
    );
}

export default Header;


