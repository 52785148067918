import React, { useState, useEffect, useRef } from "react";
import Anzenza from './images/Anzenza.png';
import Azziad from './images/Azziad.png';
import Flaqo from './images/Flaqo.png';
import Grauchi from './images/Grauchi.png';
import "../components/components.css";

function About() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideTrackRef = useRef(null);
  const slides = [
    { img: Anzenza },
    { img: Azziad },
    { img: Flaqo },
    { img: Grauchi },
  ];

  const totalSlides = slides.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    }, 3000); // Adjust the time interval as needed

    return () => clearInterval(interval);
  }, [totalSlides]);

  useEffect(() => {
    slideTrackRef.current.style.transition = currentSlide === 0 ? "none" : "transform 0.5s ease";
    slideTrackRef.current.style.transform = `translateX(-${(currentSlide * 100) / totalSlides}%)`;
  }, [currentSlide, totalSlides]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="about-section">
      <h1 className="about-header">What is Nunua Chai?</h1>
      <p className="about-body">
        Nunua chai is a new platform for fans to appreciate their favourite
        creators. This will be helpful for both the fans and Creators in the
        sense that creators will be able to get value directly from their
        audiences. Fans/consumers will be able to show their appreciation in
        more than just likes and following. Additionally they will continue to
        get quality content from the creators.
      </p>
      <h1 className="about-heading">
        A platform for <span>All Creators</span>
      </h1>
      <div className="slider">
        <div
          className="slide-track"
          ref={slideTrackRef}
        >
          {slides.map((slide, index) => (
            <div className="slide" key={`slide-${index}`}>
              <img src={slide.img} alt="creator" />
            </div>
          ))}
        </div>
        <div className="dot-indicators">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default About;

