import React from "react";
import { Link } from "react-router-dom";
import "../components.css";
import placeholder from "./Placeholder.png";

const MainSection = () => {
  return (
    <div className="main-section">
      <div className="left-side">
        <div className="left-content">
          <h1>Like My work?</h1>
          <p>
            NUNUA <span>CHAI</span>
          </p>
          <div className="buttons-container">
            <Link to="/RegisterPage" className="button">Sign In</Link>
            <Link to="/Dashboard" className="button">Dashboard</Link>
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="image-container">
          <img src={placeholder} alt="random" />
        </div>
      </div>
    </div>
  );
};

export default MainSection;
