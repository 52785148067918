import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./screens/LandingPage";
import CreatorHome from "./screens/Creators/Home";
import MoreOptions from "./screens/Creators/MoreOptions";
import Membership from "./screens/Creators/Membership";
import LoginPage from "./screens/Auth/LoginPage";
import RegisterPage from "./screens/Auth/RegisterPage";
import About from "./screens/About";
import FrequentAskQuestions from "./screens/FrequentAskQuestions";
import TermsAndConditions from "./screens/TermsAndConditions";
import NewPassword from "./screens/Auth/NewPassword";
import PasswordSuccess from "./screens/Auth/PasswordSuccess";
import PasswordRecovery from "./screens/Auth/PasswordRecovery";
import Dashboard from "./screens/Dashboard/Dashboard";
import Client from "./components/ClientFan/Client";
import Transactions from "./components/ClientFan/Transactions";
import ProtectedRoute from "./screens/Auth/ProtectedRoute";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/faqs" element={<FrequentAskQuestions />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/RegisterPage" element={<RegisterPage />} />
        <Route path="/creators" element={<CreatorHome />} />
        <Route path="/creators/membership" element={<Membership />} />
        <Route path="/creators/more-options" element={<MoreOptions />} />
        <Route path="/NewPassword" element={<NewPassword />} />
        <Route path="/PasswordSuccess" element={<PasswordSuccess />} />
        <Route path="/PasswordRecovery" element={<PasswordRecovery />} />
        <Route
          path="/Dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Client"
          element={
            <ProtectedRoute>
              <Client />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transactions"
          element={
            <ProtectedRoute>
              <Transactions />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
