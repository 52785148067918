import { Navigate } from 'react-router-dom';
import { isSessionValid } from './sessionUtils'; // Import the utility function

const ProtectedRoute = ({ children }) => {
  const sessionIsValid = isSessionValid();

  if (!sessionIsValid) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
