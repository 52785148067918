import React from "react";
import { Link } from "react-router-dom";
import "./components.css";

const SideBar = ({ isActive, closeSidebar }) => {
  return (
    <div className={`sidebar ${isActive ? "active" : ""}`}>
      <button className="close-button" onClick={closeSidebar}>
        x
      </button>
      <div className="side-items">
        <Link to="/Terms">Terms & Condition</Link>
        <Link to="/FAQ">FAQ</Link>
        <Link to="/About">About</Link>
      </div>
      <div className="sidebar-bottom">
        <button className="get-started">Sign up</button>
      </div>
    </div>
  );
};

export default SideBar;
