import React from "react";
import { useNavigate } from "react-router-dom";
import "./PasswordRecovery.css";
import logo from "./logo2.png"; // Adjust the import path as needed

const PasswordRecovery = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="containerPR">
      <header className="headerPR">
        <div className="logo">
          <img src={logo} alt="Nunuà Chai Logo" />
        </div>
        <nav className="nav">
          <a href="/about">About</a>
          <a href="/faqs">Faqs</a>
          <a href="/reviews">Reviews</a>
        </nav>
      </header>
      <div className="back-arrow" onClick={handleBackClick}>&larr;</div>
      <div className="content">
        <h1 className="title">
          Recover <span className="highlight">password</span>
        </h1>
        <form className="form">
          <label htmlFor="email" className="email-label">write your registered email address</label>
          <input type="email" id="email" className="input-field" />
          <button type="submit" className="submit-button">Submit</button>
        </form>
        <div className="links">
          <p>Not yet registered? <a href="/RegisterPage" className="highlight">sign up</a></p>
          <p>Remembered your password? <a href="/login" className="login-link">log in</a></p>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecovery;
