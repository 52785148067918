import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';

const Dashboard = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await axios.get('https://staging-chai-api.onrender.com/api/dashboard/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setDashboardData(response.data);
      } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div className="dashboard-app">
      <aside className="dashboard-sidebar">
        <div className="logo">
          <img src="/logo2.png" alt="Logo" />
        </div>
        <div className="profile">
          <label htmlFor="profile-upload">
            {profileImage ? (
              <img src={profileImage} alt="Profile" className="profile-image" />
            ) : (
              <img src="/Profile.png" alt="Profile" className="profile-image" />
            )}
          </label>
          <input
            type="file"
            id="profile-upload"
            onChange={handleImageChange}
          />
          <p>Profile</p>
        </div>
        <nav className="nav">
          <ul>
            <li className="active">Dashboard</li>
            <li>Edit tiers</li>
            <li>Withdraw</li>
            <li>Faqs</li>
            <li>T & C's</li>
            <li>Transactions</li>
            <li>Edit Profile</li>
          </ul>
        </nav>
      </aside>
      <main className="dashboard-main">
        <header className="dashboard-header">
          <div className="welcome">
            <h1>Welcome</h1>
          </div>
          <div className="search">
            <input type="text" placeholder="Search for creators" />
            <i className="search-icon">&#9906;</i>
          </div>
        </header>
        <section className="dashboard-content">
          {dashboardData && (
            <div>
              <p>Balance: {dashboardData.balance}</p>
              <p>Recent Transactions:</p>
              <table className="transactions-table">
                <thead>
                  <tr>
                    <th>Creator</th>
                    <th>Tier</th>
                    <th>Sponsor name</th>
                    <th>Sponsor phone</th>
                    <th>Amount</th>
                    <th>Message</th>
                    <th>Created at</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData.recent_transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.creator}</td>
                      <td>{transaction.tier}</td>
                      <td>{transaction.sponsor_name}</td>
                      <td>{transaction.sponsor_phone}</td>
                      <td>{transaction.amount}</td>
                      <td>{transaction.message}</td>
                      <td>{transaction.created_at}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Add other fields as needed */}
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default Dashboard;
