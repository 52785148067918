import React from 'react';
import { Link } from 'react-router-dom';

const MoreOptions = () => {
    return (
        <div>
        <h1>More Options</h1>
        <Link to="/creators">Back to Creators</Link>
        </div>
    );
    }   
export default MoreOptions;
