//landing page for the app
import React from "react";
import NavBar from "../components/NavBar.js";
import MainSection from "../components/Mainsection/MainSection.js";
import About from "./About.js";

function LandingPage() {
  return (
    <div>
      <NavBar />
      <MainSection/>
     <About/>
    </div>
  );
}

export default LandingPage;
