import React from "react";
import { FiSearch } from "react-icons/fi";
import "../components.css";

function SearchBar() {
  return (
    <div className="search-bar">
      <FiSearch className="search-icon" />
      <input type="text" placeholder="Search Creators" />
    </div>
  );
}

export default SearchBar;
