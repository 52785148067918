import React from 'react';

const CreatorHome = () => {
  return (
    <div>
      <h1>Creator Home</h1>
    </div>
  );
}

export default CreatorHome;