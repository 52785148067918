import React from "react";
import "./PasswordSuccess.css";
import logo from "./logo2.png"; 

const PasswordSuccess = () => {
  return (
    <div className="containerPS">
      <header className="header">
        <div className="back-arrow">&larr;</div>
        <div className="logo">
          <img src={logo} alt="Nunuà Chai Logo" />
        </div>
        <nav className="nav">
          <a href="/about">About</a>
          <a href="/faqs">Faqs</a>
          <a href="/reviews">Reviews</a>
        </nav>
      </header>
      <div className="content">
        <h1 className="title">
          Password <span className="highlight">updated successfully!</span>
        </h1>
        <a href="/login" className="login-button">
          Return to <span className="highlight-login">login page</span>
        </a>
        <div className="links">
          <p>
            Not yet registered? <a href="/RegisterPage" className="highlight-link">sign up</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PasswordSuccess;
