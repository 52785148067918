import React from "react";
import "./NewPassword.css";
import logo from "./logo2.png"; 
const NewPassword = () => {
  return (
    <div>
      <header className="headerN">
        <div className="logoN">
          <img src={logo} alt="Nunuà Chai Logo" />
        </div>
        <nav className="nav">
          <a href="/about">About</a>
          <a href="/faqs">Faqs</a>
          <a href="/reviews">Reviews</a>
        </nav>
      </header>
      <div className="containerN">
        <div className="back-arrow">&larr;</div>
        <div className="form-container">
          <h1 className="title">
            New <span className="highlight">password</span>
          </h1>
          <form className="form">
            <div className="form-group">
              <label htmlFor="new-password">Input new password</label>
              <input
                type="password"
                id="new-password"
                className="input-field"
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">Input new password again</label>
              <input
                type="password"
                id="confirm-password"
                className="input-field"
              />
            </div>
            <p className="password-hint">Password must be eight characters</p>
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
          <div className="links">
            <p>
              Not yet registered? <a href="/signup">sign up</a>
            </p>
            <p>
              Remembered your password? <a href="/login">log in</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
